@media only screen and (max-width: 768px){
  body{
    -webkit-text-size-adjust: 100%;
  }
  .operatorDealsHeader {
    background: url("../../images/dealBg.png");
    height: 50px;
    padding: 2%;
    font-family: "Poppins";
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .box{
  border-radius: 10px;
  border: 1px solid #DFDFDF;
  margin: 10px;
  padding: 10px;

    .details{
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
        .content{
          display: flex;
          align-items: center;
          span{
            font-family: "Poppins-Medium";
          }
          img{
            margin-left: 2px;
          }
        }
    }
    .name{
      font-family: "Inter-Light";
      font-size: 12px;
    }
    .dayDetails{
      display: flex;
      align-items: center;
      margin: 10px 0px;
      span{
        font-family: "Poppins";
        font-size: 12px;
      }
      span:nth-child(2){
        margin: 0 5%;
      }
    }
    .serviceDetails{
      display: flex;
      align-items: center;
      justify-content: space-between;
      span:nth-child(1){
      font-family: "Poppins";
  color: #118500;
  font-size: 12px;
      }
      span:nth-child(2){
        font-family: "Poppins-Medium";
    color: #7b2bff;
    font-size: 12px;
        }
    }
  }

  .dealButton{
    background-color: #7b2bff;
  color: white;
  border-radius: 10px;
    p{
      padding: 0px 9px;
      font-family: "Poppins";
      font-size: 12px;
    }

  }

  .expired{
    font-family: "Poppins-Medium";
    font-size: 12px;
    color: #A30000;
    background: #C700001A;
    padding: 5px;
    border-radius: 10px;
  }
  .cancel{
    border-radius: 10px;
    width: 100%;
    color: #7b2bff;
    font-size: 14px;
    font-family: "Poppins-Medium";
    border: none;
  }
}

@media only screen and (min-width: 768px) {
  .operatorDealsHeader {
    height: 50px;
    padding: 2%;
    font-family: "Poppins";
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .dealButton{
    cursor: pointer;
    background-color: #7b2bff;
    color: white;
    border-radius: 20px;
    p{
      padding: 0px 9px;
      font-family: "Poppins";
      font-size: 14px;
    }
  }
  .type{
    display: flex;
    img{
      width: 10%;
      margin-right:15px ;
    }
  }
  .box{
    border-radius: 10px;
    border: 1px solid #DFDFDF;
    margin: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
    div:nth-child(5){
      text-align: center;
    }
    div:nth-child(6){
      cursor: pointer;
      width: 2%;
      text-align: end;
    }
    p{
      font-family: "Inter";
      font-size: 12px;
    }
    .extra{
      font-family: "Inter-Light";
      font-size: 12px;
      color: #118500;
    }
    .name{
      font-family: "Inter-Light";
      font-size: 12px;
    }
    .details{
      width: 25%;
      padding: 10px;
      text-align: start;
    }
    .expired{
      font-family: "Inter";
      font-weight: 700;
      font-size: 12px;
      color: #A30000;
      background: #C700001A;
      padding: 5px;
      border-radius: 10px;
    }
  }
}
