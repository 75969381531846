.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 101;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.side-drawer {
  display: flex;
  height: 100vh;
  background: rgb(252, 252, 252);
  position: fixed;
  top: 0;
  right: 0;
  width: 55%;
  z-index: 1020;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  transform: translateX(100%);
  transition: transform 0.8s ease-out !important;
}

.width35 {
  width: 35%;
}
.side-drawer.open {
  transform: translateX(0);
}

.backIcon {
  display: flex;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0px;
  left: 0px;
  margin: 24px 24px 24px 28px;
  cursor: pointer;
}

.sliderDrawerLoungeDetails {
  display: flex;
  height: 100vh;
  background: rgb(252, 252, 252);
  overflow-y: scroll;
  position: fixed;
  top: 0;
  right: 0;
  width: 40%;
  z-index: 999;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  transform: translateX(100%);
  transition: transform 0.4s ease-out !important;
}

.sliderDrawerLoungeDetailsOpen {
  display: flex;
  height: 100vh;
  background: rgb(252, 252, 252);
  overflow-y: scroll;
  position: fixed;
  margin-right: 0px !important;
  top: 0;
  right: 0;
  width: 38%;
  z-index: 200;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);

  transition: transform 0.4s ease-out !important;
  transform: translateX(0);

  .closeIcon {
    margin-right: 40px;
    img {
      display: flex;
      visibility: visible;
      width: 20px;
      height: 20px;
      position: absolute;
      left: 93.8% !important;
      top: 15px;
      bottom: 94.27%;
      cursor: pointer;
      z-index: 2 !important;
    }
  }
  .backIcon {
    img {
      display: flex;
      visibility: hidden;
      width: 20px;
      height: 20px;
      position: absolute;
      left: 4.8% !important;
      top: 3.2%;
      bottom: 95.07%;
      cursor: pointer;
      z-index: 2 !important;
    }
  }
}

.menuSlideDrawer {
  position: fixed;
  top: 0;
  height: 100%;
  width: 15vw;
  min-width: 220px !important;
  background-color: #ffffff;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow-x: hidden;
  transition: left 0.3s ease-in-out;
  z-index: 101;

  &.open {
    left: 0;
  }

  .menuToggle {
    margin-left: auto;
    margin-top: 70px;
    padding: 10px;
    width: 100%;

    img {
      cursor: pointer;
    }

    .menuText {
      font-family: "Montserrat-Medium";
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 26.82px;
      color: #000000;
      vertical-align: center;
      margin-left: 20px;
      margin-top: 10px;
    }
    .closeMenuIcon {
      float: right !important;
    }
  }

  .menuItems {
    flex-grow: 1;
    overflow-y: auto;
  }
}

@media (max-width: 768px) {
  .menuWrapper {
    flex-direction: column;
  }

  .backgroundBlur {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(1px);
    z-index: 100;
  }

  .menuSlideDrawer {
    right: 0;
    width: 72%;
    z-index: 101;

    .menuToggle {
      display: flex;
      justify-content: space-between !important;
      align-items: center;
      margin-left: 0;
      margin-top: 20px;
      margin-bottom: 6.5px;
      padding: 10px;
      cursor: pointer;
      height: 0px;
      width: 90%;
      span {
        font-style: "normal";
        font-weight: 700;
        margin-left: 0;
      }

      img {
        margin-right: 0;
      }
    }
    .menuItems {
      flex-grow: 1;
      overflow-y: auto;
      margin-top: 15px;
      padding: 0px 10px 10px 10px;
    }
  }
}
