.bottomDrawerOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(1px);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1000;
}

.bottomDrawer {
  font-family: "Poppins", sans-serif;
  background-color: white;
  width: 100%;
  max-height: 90%;
  border-radius: 12px 12px 0 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  z-index: 1001;
}

.bottomDrawerHeader {
  font-family: "Poppins", sans-serif;
  position: sticky;
  top: 0;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  border-bottom: 1px solid #ddd;
  z-index: 10;
}

.bottomDrawerHeading {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.bottomDrawerCloseIcon {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.bottomDrawerContent {
  padding: 16px;
  overflow-y: auto;
}
