.DesktopWrapper{
    width: 100%;
    overflow: hidden;

    .background{
        height: 100vh;
        width: 70%;
        float: left;
        overflow: hidden;
        background: url(https://d1flzashw70bti.cloudfront.net/next-gen/images/operator/login/image.webp) ;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .detail{
        width: 30%;
        height: 100vh;
        overflow: hidden;
    }

    .login{
        padding: 2%;
        position: absolute;
        top: 40%;
        width: -moz-available;
        width: -webkit-fill-available;
        width: strech;
        button{
            cursor: pointer;
        }
        >p:nth-child(2){
            color: #7E7E7E;
            margin-bottom: 8%;
        }
        .textHeader{
            font-family: "Poppins-Medium";
        }
        .textSubHeader{
            font-family: "Poppins-Medium";
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: 0em;
        }
        .mobileNumberInput{
            margin-top: 20px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            border: 1px solid rgba(89, 0, 234, 0.45);
            span{
                font-family: "Poppins-Medium";
                padding: 0% 2%;
                font-size: 12px;
                width: 10px;
                font-weight: 400;
            }
            .vl{
                border-left: 1px solid rgba(89, 0, 234, 0.45);
                height: 100%;
                margin-left: 10px;
            }
            input{
                width: 100%;
                height: 40px;
                margin-left: 10px;
                border: none;
                font-family: "Poppins-Medium";
                font-size: 12px;
                    &:focus-within {
                    box-shadow: none;
                    height: 40px;

                    border: none;
                }
            }
        }
        button{
            background: #7B2AFF;
            font-family: "Poppins-Medium";
            color: white;
            width: 100%;
            height: 50px;
            padding: 10px 20px 10px 20px;
            border-radius: 10px;
            margin-top: 10px;
            border: none;
        }

    }
    }