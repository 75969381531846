.midScreenPopUpBackground {
  font-family: "Poppins", sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.midScreenPopUpContainer {
  background: #fff;
  border-radius: 15px;
  padding: 20px;
  width: 80%;
  max-width: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
}

.popupImage {
  max-width: 100px;
  max-height: 100px;
  margin: 0 auto 20px;
  display: block;
}

.popupText {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.popupSubText {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
}

.popupButton {
  font-family: "Poppins", sans-serif;
  width: 100%;
  padding: 10px 0;
  background: #7b2aff1a;
  color: #7b2aff;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.popupButton:hover {
  background: #7b2aff1a;
}
