@media screen and (max-width: 767px) {
  body {
    -webkit-text-size-adjust: 100%;
  }
  .loginDiv {
    .container {
      padding: 0%;
      height: 100vh;
    }
    .header {
      height: 65%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #7b2aff;
    }
    .login {
      padding: 2%;
      .textHeader {
        font-family: "Poppins-Medium";
      }
      .textSubHeader {
        font-family: "Poppins-Medium";
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: center;
      }
      .mobileNumberInput {
        margin-top: 20px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        border: 1px solid rgba(89, 0, 234, 0.45);
        span {
          font-family: "Poppins-Medium";
          padding: 0% 2%;
          font-size: 12px;
          width: 10px;
          font-weight: 400;
        }
        .vl {
          border-left: 1px solid rgba(89, 0, 234, 0.45);
          height: 100%;
          margin-left: 10px;
        }
        input {
          width: 100%;
          margin-left: 10px;
          border: none;
          height: 40px;
          font-family: "Poppins-Medium";
          font-size: 12px;
          &:focus-within {
            box-shadow: none;
            height: 40px;
            border: none;
          }
        }
      }
      button {
        background: #7b2aff;
        font-family: "Poppins-Medium";
        color: white;
        width: 100%;
        height: 50px;
        padding: 10px 20px 10px 20px;
        border-radius: 10px;
        margin-top: 10px;
        border: none;
      }
    }
  }
  .ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
    visibility: visible !important;
  }

  .ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
    visibility: visible !important;
  }

  .ant-picker-panels > *:last-child {
    display: none;
  }

  .ant-picker-panel-container,
  .ant-picker-footer {
    width: 280px !important;
  }

  .ant-picker-footer-extra > div {
    flex-wrap: wrap !important;
  }
}
