#SideNav {
  .menu {
    .menuItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 15px;
      margin-bottom: 20px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #f6f6f6;
      }

      &.active {
        background-color: #f6f6f6;
      }

      .menuContent {
        display: flex;
        align-items: center;

        .menuIcon {
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }

        .menuItemFont {
          text-decoration: none;
          font-size: 16px;
          font-weight: 500;
          color: #292929;
          text-align: center;
        }
      }

      .rightArrow {
        font-size: 18px;
        color: #7b7b7b;
      }
    }
  }
}
