.amenitiesRow {
  justify-content: start;
  > div {
    display: flex;
    > .bulkDiv {
      display: flex;
      justify-content: center;
      border: none;
      color: white;
      margin: 10px;
      > span {
        cursor: pointer;
        font-family: "Poppins-Medium";
        margin-right: 10px;
        font-size: 12px;
        background: #8f00ff;
        border-radius: 10px;
        padding: 10px 5px;
      }
    }
    > .InActivebulkDiv {
      display: flex;
      justify-content: center;
      border: none;
      color: white;
      margin: 10px;
      > span {
        cursor: pointer;
        font-family: "Poppins-Medium";
        font-size: 12px;
        margin-right: 10px;
        background: #cccccc;
        border-radius: 10px;
        padding: 10px 5px;
      }
    }
  }
}
.searchHeader {
  background: rgb(248, 248, 248);
  display: flex;
  align-items: center;
  padding: 15px;
  justify-content: space-between;
  > div:nth-child(1) {
    width: 40%;
    display: flex;
    gap: 10px;
    align-items: center;
  }
  > div:nth-child(2) {
    display: flex;
    gap: 20px;
    > div > img {
      margin: 0 10px;
    }
    > div > span {
      margin: 0px 10px;
      font-family: "Poppins-Medium";
      font-size: 12px;
    }
    > img {
      cursor: pointer;
      background: #7b2bff;
      padding: 5px;
      border-radius: 10px;
    }
  }
}
.TextArea {
  margin-bottom: 20px;
}
.tableTitle {
  display: flex;
  align-items: center;
  > span {
    margin-right: 5px;
  }
}
.commisiondiv {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  > span {
    font-family: Poppins;
    font-size: 14px;
    margin-right: 10px;
  }
}
.modalDateInfo {
  margin-bottom: 10px;
  > span:nth-child(1) {
    font-family: "Poppins-Medium";
    font-size: 14px;
  }
  > span:nth-child(2) {
    color: #7b2bff;
    font-family: "Poppins-Medium";
    font-size: 14px;
  }
}
.divButton {
  display: flex;
  justify-content: space-around;
  align-items: center;
  > button:nth-child(1) {
    cursor: pointer;
    width: 48%;
    background: #ededed;
    color: black;
    border: none;
    padding: 10px;
    border-radius: 10px;
    font-family: "Poppins";
  }
  > button:nth-child(2) {
    cursor: pointer;
    width: 48%;
    padding: 10px;
    border: none;
    border-radius: 10px;
    font-family: "Poppins";
    background: #7b2aff;
    color: white;
  }
}
.tableDiv {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  > img {
    cursor: pointer;
  }
}
.tableType {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}
.bulkServiceUpdate {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.loader {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 50%;
  right: 50%;
}
.attachment {
  cursor: pointer;
  > div {
    display: flex;
    align-items: center;
  }
}
.moreDetailsModal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  > img {
    cursor: pointer;
  }
  > div:nth-child(1) {
    display: flex;
    align-items: center;
    gap: 12px;
    > * {
      color: #292929;
      font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    > p:nth-child(1) {
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
    }
  }
}
@media screen and (max-width: 768px) {
  .pageHeading {
    display: flex;
    margin-left: 20px;
    .backImg {
      margin-right: 10px;
    }
    .pageHeadingText {
      font-family: Poppins;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      text-align: left;
    }
  }
  .searchHeader {
    background: rgb(248, 248, 248);
    display: block;
    padding: 15px;
    justify-content: space-between;
    > div:nth-child(1) {
      width: 100%;
      display: flex;
      align-items: center;
      > div {
        width: 100%;
        display: flex;
        gap: 5px;
        > div {
          display: flex;
          > img {
            margin: 0 10px;
          }
          > span {
            margin: 0px 10px;
            font-family: "Poppins-Medium";
            font-size: 12px;
          }
          > img {
            cursor: pointer;
            background: #7b2bff;
            padding: 5px;
            border-radius: 10px;
          }
        }
      }
    }
    > div:nth-child(2) {
      display: flex;
      gap: 20px;
      > div > img {
        margin: 0 10px;
      }
      > div > span {
        margin: 0px 10px;
        font-family: "Poppins-Medium";
        font-size: 12px;
      }
      > img {
        cursor: pointer;
        background: #7b2bff;
        padding: 5px;
        border-radius: 10px;
      }
    }
  }
  .selectInspection {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 10px 0px 10px;
    > .active {
      border-radius: 41px;
      background: #8f00ff;
      > span {
        color: #fff;
        padding: 20px;
        text-align: center;
        font-feature-settings: "ss01" on, "cv01" on, "cv11" on;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 166.667% */
      }
    }
    > .inActive {
      border-radius: 41px;
      background: #f6f6f6;
      > span {
        color: #292929;
        padding: 20px;
        text-align: center;
        font-feature-settings: "ss01" on, "cv01" on, "cv11" on;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 166.667% */
      }
    }
  }
  .inspectionDataContainer {
    display: block;
    align-items: center;
  }
}
