.passengerDetailsTile {
  font-family: "Poppins", sans-serif;
  width: 88%;
  margin: 10px auto;
  padding: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  .detailsHeader {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
    color: #454545;
  }

  .center {
    margin-bottom: 5px;
    .marshalDetails {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      line-height: 18px;
      font-weight: normal;

      .callWrapper {
        border-radius: 8px;
        padding: 6px 10px;
        background-color: #7b2aff1a;
        display: flex;
        align-items: center;
        .callIcon {
          width: 14px;
          height: 14px;
        }
        .text {
          margin-left: 8px;
          font-size: 12px;
          color: #7b2aff;
        }
      }
    }
  }

  .moreDetails {
    display: flex;
    align-items: center;
    .viewMoreLessToggle {
      font-size: 12px;
      line-height: 18px;
      font-weight: 600;
      color: #7b2aff;
    }
    .detailsIcon {
      width: 14px;
      height: 14px;
      margin-left: 5px;
      transform: rotate(90deg);
    }
    .viewLess {
      transform: rotate(-90deg);
    }
  }

  .extendedTile {
    margin-top: 15px;
    padding-top: 10px;
    border-top: 1px solid #e0e0e0;

    .infoGroup {
      margin-bottom: 10px;

      .labelAndTime {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        font-weight: 600;
        color: #454545;
        margin-bottom: 5px;
        .label {
          margin-left: 0px;
        }
      }

      .address {
        font-size: 12px;
        color: #6c757d;
        word-wrap: break-word;
        line-height: 1.4;
      }
    }

    .arrowWrapper {
      display: flex;
      align-items: center;
      justify-content: left;
      margin: 20px 0px 10px 0px;

      .arrowIcon {
        width: 14px;
        height: 14px;
        transform: rotate(90deg);
      }
    }
  }
}
