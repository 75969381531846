.Header{
    height: 50px;
    padding: 2% 3% 0px 0%;
    font-family: "Poppins";
    display: flex;
    align-items: center;
    justify-content: space-between;
    >div>span{
        font-family: "Poppins-Medium";
        margin-right: 10px;
        font-size: 12px;
    }
  }

.info{
    margin-bottom: 10px;
    padding: 10px;
    background: #f5f5f5;
    >span{
        font-family: "Poppins-Medium";
            font-size: 12px;
    }
    .grid{
        margin-top: 10px;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: minmax(30px, auto);
        display: grid;
        >div{
        >span:nth-child(1){
            font-family: "Poppins";
            font-size: 12px;
        }
        >span:nth-child(2){
            font-family: "Poppins-Medium";
            font-size: 12px;
            margin-left: 10px;
        }
    }
    }

}