.tripDetailsTile {
  font-family: "Poppins", sans-serif;
  width: 88%;
  margin: 10px auto;
  padding: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  .tripHeader {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    line-height: 18px;
    color: #454545;
    font-weight: 400;
    margin-bottom: 10px;

    .tripDateAndTime {
      .tripDate {
        margin-right: 10px;
      }
      .tripTime {
        font-style: bold;
        font-weight: 700;
      }
    }
  }

  .tripRoute {
    margin-bottom: 10px;
    .routeFromTo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 15px;
      line-height: 22.5px;
      color: #292929;
      font-weight: 600;

      .arrowIcon {
        width: 14px;
        height: 14px;
        margin: 0 5px;
      }
    }
  }

  .tripDetails {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
    color: #454545;

    .seater {
      margin-right: 5px;
    }
  }
}
