.container {
  font-family: "Poppins", sans-serif;
  .assignDriverButton {
    margin: 0px 8px;
    button {
      width: 100%;
      height: 40px;
      border: none;
      border-radius: 8px;
      background-color: #7b2aff;
      color: #ffffff;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      font-style: bold;
      font-weight: 600;
    }
  }

  .driverDetailsContainer {
    margin: 10px 0px;
    width: 100%;
    font-family: "Poppins", sans-serif;
    .assignmentStatus {
      margin: 8px 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      line-height: 18px;
      .status {
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
          margin-left: 5px;
        }
      }
      .editDetails {
        color: #7b2aff;
        font-weight: 600;
      }
    }
    .driverDetails {
      background-color: #cbe3cc;
      margin: 0px 12px;
      border-radius: 8px;
      padding: 8px 8px 4px 8px;
      .info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 4px;
        .text {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }

  .label {
    margin: 15px 10px 8px 10px;
    .text {
      font-style: bold;
      font-size: 15px;
      line-height: 22.5px;
      font-weight: 600;
    }
  }
}
