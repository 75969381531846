.emptyViewContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80vh;
  text-align: center;
}

.emptyViewLogo {
  margin-bottom: 20px;
}

.emptyViewText {
  font-size: 16px;
  color: #777777;
}
