.trip-search-filters {
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.filter-field {
  width: 100%;
}

.filter-input {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 12px;
  line-height: 18px;
  box-sizing: border-box;
}

.switch-icon {
  text-align: left;
  font-size: 20px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  padding-left: 10px;
}

.date-range {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-start;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  width: 93.5%;
}

.calendar-icon {
  font-size: 20px;
}

.date-text {
  cursor: pointer;
  color: #454545;
  font-size: 12px;
  line-height: 18px;
}

.search-btn button {
  width: 100%;
  padding: 15px;
  background-color: #7b2aff;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.search-btn button:hover {
  background-color: #7b2aff;
}

/* Custom Datepicker Styles */
.custom-datepicker {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 10px;
  z-index: 9999;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 5px;
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: auto;
}

.centered-datepicker {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.calendar-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
