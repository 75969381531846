.mobileSubHeader {
  position: fixed;
  top: 56px;
  left: 0;
  width: 100%;
  height: 48px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 26px;
  z-index: 15;

  .subHeaderContent {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10;
  }

  .subHeaderText {
    font-size: 16px;
    font-weight: 600;
    color: #333;
  }

  .subHeaderIcon {
    margin-right: 56px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  &.moveUp {
    top: 0px;
    transition: top 0.3s ease;
  }
}
