/* @import "~antd/dist/reset.css"; */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
/* 
.modalClass .ant-modal-wrap {
  overflow: hidden;
}
.modalClass .ant-modal-content {
  border-radius: 20px 0px 0px 20px;
} */

.loader {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 50%;
  right: 50%;
}

@media screen and (max-width: 768px) {
  .ant-picker-outlined {
    width: 250%;
  }
  .dateRangePicker .ant-picker-panel:nth-child(2) {
    display: none;
  }

  .dateRangePicker .ant-picker-panel:nth-child(1) button {
    visibility: visible !important;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@font-face {
  font-family: Poppins;
  src: url("fonts/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: Poppins-BlackItalic;
  src: url("fonts/Poppins-BlackItalic.ttf") format("truetype");
}
@font-face {
  font-family: Poppins-Bold;
  src: url("fonts/Poppins-Bold.ttf") format("truetype");
}
@font-face {
  font-family: Poppins-Medium;
  src: url("fonts/Poppins-Medium.ttf") format("truetype");
}
@font-face {
  font-family: Poppins-Black;
  src: url("fonts/Poppins-Black.ttf") format("truetype");
}
@font-face {
  font-family: Poppins-Light;
  src: url("fonts/Poppins-Light.ttf") format("truetype");
}
@font-face {
  font-family: Inter;
  src: url("fonts/Inter-Bold.otf") format("truetype");
}
@font-face {
  font-family: Inter-Light;
  src: url("fonts/Inter-Light.otf") format("truetype");
}
