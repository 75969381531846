.amenitiesRow{
    justify-content: start;
    >div{
    display: flex;
       
        >.bulkDiv{
            display: flex;
            justify-content: center;
            border: none;
            color: white;
            margin: 10px;
            >span{
                cursor: pointer;
                font-family: "Poppins-Medium";
                margin-right: 10px;
                font-size: 12px;
                background: #8F00FF;
                border-radius: 10px;
                padding: 10px 5px;
            }
            
        }
        >.InActivebulkDiv{
            display: flex;
            justify-content: center;
            border: none;
            color: white;
            margin: 10px;
            >span{
                cursor: pointer;
                font-family: "Poppins-Medium";
                font-size: 12px;
                margin-right: 10px;
                background: #CCCCCC;
                border-radius: 10px;
                padding: 10px 5px;
            }
        }
    }
}

.searchHeader{
    background: rgb(248, 248, 248);
    display: flex;
    align-items: center;
    padding: 15px;
    justify-content: space-between;
    >div>img{
        margin: 0 10px;
    }
    >div>span{
        margin: 0px 10px;
        font-family: "Poppins-Medium";
        font-size: 12px;

    }
    >img{
        cursor: pointer;
        background: #7b2bff;
    padding: 5px;
    border-radius: 10px;
    }
}

.TextArea{
    margin-bottom: 20px;
}

.tableTitle{
    display: flex;
    align-items: center;
    >span{
        margin-right: 5px;
    }
}

.commisiondiv{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    >span{
        font-family: Poppins;
        font-size: 14px;
        margin-right: 10px;
    }
}

.modalDateInfo{
    margin-bottom: 10px;

    >span:nth-child(1){
        font-family: "Poppins-Medium";
        font-size: 14px;
    }
    >span:nth-child(2){
        color: #7b2bff;
        font-family: "Poppins-Medium";
        font-size: 14px;
    }
}
.divButton{
    display: flex;
    justify-content: space-around;
    align-items: center;
    >button:nth-child(1){
        cursor: pointer;
        width: 48%;
        background: #EDEDED;
        color: black;
        border: none;
        padding: 10px;
        border-radius: 10px;
        font-family: "Poppins";
    }
    >button:nth-child(2){
        cursor: pointer;
        width: 48%;
        padding: 10px;
        border: none;
        border-radius: 10px;
        font-family: "Poppins";
        background: #7B2AFF;
        color: white;
    }
}

.tableDiv{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    >img{
        cursor: pointer;
    }
}

.tableType{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column
}

.bulkServiceUpdate{
    display: flex;
    justify-content: space-between;
    align-items: center;

}