.mobileMainHeader {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 56px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  padding: 0 10px;
  z-index: 30;
  transition: top 0.3s ease;

  .menuIcon {
    cursor: pointer;
    margin-right: 40px;
    img {
      width: 24px;
      height: 24px;
    }
  }

  .logo {
    flex-grow: 1;
    text-align: left;
    margin-left: 10px;
  }

  &.hidden {
    top: -56px;
  }
}

.pageContent {
  margin-top: 56px;
  padding: 10px;
}
