.numberInfo{
  font-family: "Poppins-Medium";
  font-size: 12px;
  font-weight: 400;
  color: rgb(69, 69, 69);
  font-size: 12px;
}

.resendOtp{
    font-family: "Poppins-Medium";
    color: rgb(69, 69, 69);
    font-size: 12px;
}

.resendButton{
  font-family: "Poppins-Medium";
  color:#7B2AFF ;
  font-size: 12px;
  margin-left: 1%;
  cursor: pointer;
}

.wrongOtp{
  font-family: "Poppins-Medium";
    color: #EA0000;
    font-size: 12px;
}

.otpContainer {
    margin: 5% auto;
    text-align: center;
  }


.otpInput {
  width: 3rem !important;
  height: 3rem;
  margin: 0 10px;
  font-size: 1rem;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #D6C1F8;
  box-shadow: 0px 1px 4px 0px #D6C1F8;

}

.otpInputDesktop {
  width: 100%;
  height: 3rem;
  font-size: 1rem;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #D6C1F8;
  box-shadow: 0px 1px 4px 0px #D6C1F8;

}

.editInfo{
  cursor: pointer;
  font-family:  "Poppins-Medium";
font-size: 12px;
font-weight: 600;
color: #7B2AFF;

}