@media only screen and (max-width: 768px) {
    body{
        -webkit-text-size-adjust: 100%;
      }

.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2% 3%;
}
.grey{
    background: #F3F1F6;
    height: 3px;
}

.user{
    font-family: "Poppins-Medium";
    padding: 1% 3%;

font-size: 16px;
font-weight: 500;
}

.Details{
    border-bottom: 1px solid #EEEEEE;
    display: flex;
    padding: 1% 2% 5% 2%;
    margin: 0 5%;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    .userDetail{
        padding: 5px;
        .Bold {
        font-family: "Poppins-Medium";
        font-size: 14px;
        font-weight: 500;
    }
    .light{
        font-family: " Poppins-Light";
        font-size: 14px;
        font-weight: 500;
    }
    }

}
.PocHeader{
    margin-bottom: 10px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .PocBtn{
        background: #7B2AFF;
        border-radius: 20px;
        padding:1% 2%;
        margin-right: 2%;
.Poctxt{
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;

}
    }
}
.POCModal{
    display: flex;
    flex-direction: column;
    .modalHeader{
        font-family: "Poppins-Medium";
        font-size: 18px;
        text-align: center;
        letter-spacing: 0em;
    }
    .subHeader{
        font-family: "Poppins-Medium";
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
    }
    .textArea{
        margin: 10px 0px;
    }
    .ant-input:focus {
        border-color: #7B2AFF;
        outline: 0;
        -webkit-box-shadow: 0 0 0 2px rgba(87,168,233, .2);
        box-shadow: 0 0 0 2px rgba(87,168,233, .2);
    }
    .addBtn{
border-radius: 10px;
background: #7B2AFF;
height: 44px;
        span{
            font-family:"Poppins-Medium" ;
            color: white;
        }

    }
}
.loader{
    display: block;
    position: fixed;
    z-index: 1031;
    top: 50%;
    right: 50%;
}

.primary {
    padding: 11.5px 0px 11.5px 0px;
    border-radius: 10px;
    background: #7B2AFF;
    color: #EEEEEE;
    width: 100%;
    font-size: 14px;
    font-family: "Poppins-Medium";
    border: none;
}
}

@media only screen and (min-width: 768px) {
    .container{
        width: 83%;
        float: right;
    }

    .user{
        font-family: "Poppins-Medium";
        font-size: 16px;
        font-weight: 500;
    }
    .Details{
        margin-top: 10px;
        border-bottom: 1px solid #EEEEEE;
        padding-bottom: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
    
        .userDetail{
            padding: 5px;
            margin-left: 15px;
            .Bold {
            font-family: "Poppins-Medium";
            font-size: 16px;
            font-weight: 500;
        }
            .light{
                font-family: " Poppins-Light";
                font-size: 16px;
                font-weight: 500;
            }
        }
    
    }
    .PocHeader{
        margin-bottom: 10px;
        margin-top: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .PocBtn{
            cursor: pointer;
            background: #7B2AFF;
            border-radius: 20px;
            padding:0% 2%;
            margin-right: 2%;
    .Poctxt{
        font-family: "Poppins";
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
    
    }
        }
    }
    .POCModal{
        display: flex;
        flex-direction: column;
        .modalHeader{
            font-family: "Poppins-Medium";
            font-size: 20px;
            text-align: center;
            letter-spacing: 0em;
        }
        .subHeader{
            font-family: "Poppins";
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0em;
            text-align: center;
        }
        .textArea{
            margin-bottom: 15px;
        }
        .ant-input:focus {
            border-color: #7B2AFF;
            outline: 0;
            -webkit-box-shadow: 0 0 0 2px rgba(87,168,233, .2);
            box-shadow: 0 0 0 2px rgba(87,168,233, .2);
        }
        .addBtn{
            margin-top: 10px;
            border-radius: 10px;
            background: #7B2AFF;
            height: 44px;
            span{
                font-family:"Poppins-Medium" ;
                color: white;
            }
    
        }
    }
}
