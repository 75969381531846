@media only screen and (max-width: 768px) {
  body {
    -webkit-text-size-adjust: 100%;
  }
  .home {
    margin: 10px;
  }
  .home > div:nth-child(1) {
    align-items: center;
    p:nth-child(1) {
      font-family: "Poppins-Medium";
      font-size: 16px;
    }
    p:nth-child(2) {
      font-family: "Poppins";
      font-size: 10px;
      font-weight: 400;
      line-height: 20px;
      color: #969696;
      margin-bottom: 0px;
    }
    p:nth-child(3) {
      font-family: "Poppins";
      font-size: 10px;
      font-weight: 400;
      line-height: 20px;
      color: #969696;
    }
  }

  .home > .singleGrid {
    padding: 10px;
    background-color: #f5f5f5;
    margin-bottom: 10px;
    > p {
      font-family: "Poppins-Medium";
      font-size: 18px;
    }
    > div {
      margin: 14px 0;
      display: flex;
      align-items: center;
      > span {
        font-size: 12px;
        margin-left: 5px;
        font-family: "Poppins";
      }
    }
  }
  #root > div > div.singleGrid:nth-child(4) {
    padding: 10px;
    background-color: #f5f5f5;
    margin-top: 10px;
  }
  #grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    gap: 10px;
    grid-auto-rows: minmax(100px, auto);
  }

  #grid > div {
    padding: 10px;
    background-color: #f5f5f5;
  }

  #grid > div > div {
    margin: 14px 0;
    display: flex;
    align-items: center;
  }
  #grid > div > div > span {
    margin-left: 5px;
    font-size: 12px;
    font-family: "Poppins";
  }
  #grid > div > p {
    font-family: "Poppins-Medium";
    font-size: 18px;
  }
  .quickActions {
    padding: 3%;
    border: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div:nth-child(1) {
      display: flex;
      > img {
        margin-right: 5px;
      }
    }
  }

  .notification-item-warning {
    background-image: url("https://d1flzashw70bti.cloudfront.net/original/images/operatorDashboard/warning.svg");
    background-size: 10px;
    background-color: #f8f8f8;
    background-repeat: no-repeat;
    border-radius: 10px;
    margin-top: 2%;
    > .expireColor {
      padding: 3% 3% 3% 4%;
      > div {
        > p {
          margin: 4px 0 0 0;
          font-family: "Poppins";
          font-size: 14px;
        }
        > p:nth-child(1) {
          font-family: "Poppins-Medium";
        }
      }
    }
  }
  .notificationBtn {
    font-family: "Poppins";
    color: #7b2aff;
    height: 30px;
    margin-top: 10px;
    background: #ffffff;
    border-radius: 10px;
    border: 1px solid #e3e3e3;
  }
  .notification-item-expired {
    background-image: url("https://d1flzashw70bti.cloudfront.net/original/images/operatorDashboard/expired.svg");
    background-size: 10px;
    background-color: #f8f8f8;
    background-repeat: no-repeat;
    border-radius: 10px;
    margin-top: 2%;

    > .expireColor {
      padding: 3% 3% 3% 4%;
      > div {
        > p {
          margin: 4px 0 0 0;
          font-family: "Poppins";
          font-size: 14px;
        }
        > p:nth-child(1) {
          font-family: "Poppins-Medium";
        }
      }
    }
  }

  .ModalDealDetails {
    background: #f6f4f9;
    padding: 15px;
    border-radius: 10px;
    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      > div:nth-child(1) {
        display: flex;
        align-items: center;
        > span {
          font-family: "Poppins-Medium";
          font-size: 14px;
        }
      }
      > div:nth-child(2) {
        > span {
          font-family: "Poppins-Medium";
          font-size: 12px;
        }
      }
    }
    > p {
      font-family: "Poppins";
      font-size: 12px;
    }
  }
  .ModalDealStart {
    > p:nth-child(1) {
      font-family: "Poppins-Medium";
      font-size: 12px;
    }
    > p:nth-child(2) {
      font-family: "Poppins";
      font-size: 12px;
      background: #f1f1f1;
      border-radius: 10px;
      margin: auto;
      padding: 10px;
    }
  }
  .ModalDealEnd {
    > p:nth-child(1) {
      font-family: "Poppins-Medium";
      font-size: 12px;
    }
  }
  .ModalFooter {
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
    > .primary {
      height: 44px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: white;
      width: 40%;
      text-align: center;
      font-family: "Poppins-Medium";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border: none;
      border-radius: 10px;
      background: #7b2bff;
      cursor: pointer;
    }
    > .cancel {
      height: 44px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: black;
      width: 40%;
      text-align: center;
      font-family: "Poppins-Medium";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      background: white;
      outline: none;
      border-radius: 10px;
      border-color: #ededed;
      background: #ededed;
      cursor: pointer;
      border: none;
    }
  }

  .dealBtn {
    font-family: "Poppins";
    color: #7b2aff;
    height: 45px;
    background: #ffffff;
    border-radius: 10px;
    border: 1px solid #e3e3e3;
  }
}

@media only screen and (min-width: 768px) {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .lastUpdatedHeader {
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #969696;
  }

  #grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    gap: 10px;
    grid-auto-rows: minmax(100px, auto);
  }
  #grid > div {
    padding: 10px;
    background-color: #f5f5f5;
  }

  #grid > div > div {
    margin: 14px 0;
    display: flex;
  }
  #grid > div > div > span {
    margin-left: 5px;
    font-family: "Poppins";
  }
  #grid > div > p {
    font-family: "Poppins-Medium";
    font-size: 18px;
  }
  #grid > div > span:nth-child(2) {
    font-family: "Poppins-Medium";
    font-size: 18px;
  }
  #grid > div > span:nth-child(3) {
    font-family: "Poppins";
    font-size: 12px;
  }

  .notification-container {
    display: grid;
    grid-template-columns: repeat(2, minmax(150px, 1fr));
    grid-gap: 10px;
    // padding: 10px;
  }

  // .notification-item {
  //   background-color: #f1f1f1;
  //   padding: 20px;
  //   text-align: center;
  //   border: 1px solid #ddd;
  // }

  .notification-item-warning {
    background-image: url("https://d1flzashw70bti.cloudfront.net/original/images/operatorDashboard/warning.svg");
    background-size: 10px;
    background-color: #f8f8f8;
    background-repeat: no-repeat;
    border-radius: 10px;
    padding: 2%;
    // width: max-content;
    > .expireColor {
      display: flex;
      align-items: center;
      padding-left: 5px;
      justify-content: space-between;
      > div {
        > p:nth-child(1) {
          font-family: "Poppins-Medium";
        }
        > p {
          font-family: "Poppins";
          font-size: 14px;
        }
      }
    }
  }

  .notification-item-expired {
    background-image: url("https://d1flzashw70bti.cloudfront.net/original/images/operatorDashboard/expired.svg");
    background-size: 10px;
    background-color: #f8f8f8;
    background-repeat: no-repeat;
    border-radius: 10px;
    padding: 2%;
    // width: max-content;
    > .expireColor {
      display: flex;
      align-items: center;
      padding-left: 5px;
      justify-content: space-between;
      > div {
        > p:nth-child(1) {
          font-family: "Poppins-Medium";
        }
        > p {
          font-family: "Poppins";
          font-size: 14px;
        }
      }
    }
  }

  .quickActions {
    background-color: #f8f8f8;
    padding: 1%;
    border-radius: 10px;
    margin: 2% 0px;
    width: max-content;
    border: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
  }

  div.quickActions > img {
    margin-right: 20px;
  }
  div.quickActions > span {
    font-family: "Poppins";
    font-size: 14px;
  }

  .dealBtn {
    cursor: pointer;
    font-family: "Poppins";
    color: #7b2aff;
    height: 45px;
    background: #ffffff;
    border-radius: 20px;
    border: 1px solid #e3e3e3;
    margin-left: 140px;
    padding: 0px 20px;
  }

  .headings {
    font-family: "Poppins-Medium";
    font-size: 16px;
  }

  .ModalDealDetails {
    background: #f6f4f9;
    padding: 15px;
    border-radius: 10px;
    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      > div:nth-child(1) {
        display: flex;
        align-items: center;
        > span {
          font-family: "Poppins-Medium";
          font-size: 14px;
        }
      }
      > div:nth-child(2) {
        > span {
          font-family: "Poppins-Medium";
          font-size: 12px;
        }
      }
    }
    > p {
      font-family: "Poppins";
      font-size: 12px;
      > span {
        color: #118500;
      }
    }
  }
  .ModalDealStart {
    > p:nth-child(1) {
      font-family: "Poppins-Medium";
      font-size: 14px;
    }
    > p:nth-child(2) {
      font-family: "Poppins";
      font-size: 14px;
      background: #f1f1f1;
      border-radius: 10px;
      margin: auto;
      padding: 10px;
    }
  }
  .ModalDealEnd {
    > p:nth-child(1) {
      font-family: "Poppins-Medium";
      font-size: 14px;
    }
  }
  .ModalFooter {
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
    > .primary {
      height: 44px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: white;
      width: 40%;
      text-align: center;
      font-family: "Poppins-Medium";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border: none;
      border-radius: 10px;
      background: #7b2bff;
      cursor: pointer;
    }
    > .cancel {
      height: 44px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: black;
      width: 40%;
      text-align: center;
      font-family: "Poppins-Medium";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      background: white;
      outline: none;
      border-radius: 10px;
      border-color: #ededed;
      background: #ededed;
      cursor: pointer;
      border: none;
    }
  }
  .modalDiv {
    text-align: center;
    .modalHeader {
      font-family: "Poppins-Medium";
      font-size: 20px;
      color: #454545;
    }
    .modalSubHeader {
      font-family: "Poppins";
      font-size: 14px;
      color: #454545;
    }
    .primary {
      background-color: #7b2bff;
      font-family: "Poppins";
      font-size: 14px;
      border: none;
      color: white;
      width: 50%;
      height: 40px;
      border-radius: 20px;
    }
    .rectangle {
      border-radius: 10px;
    }
    .cancelButton {
      font-family: "Poppins";
      font-size: 14px;
      color: #7b2bff;
    }
  }
}
