.assignDriverForm {
  font-family: "Poppins", sans-serif;

  .formField {
    margin-bottom: 16px;

    label {
      display: block;
      margin-bottom: 4px;
      font-size: 14px;
      color: #333;
    }

    input {
      width: 100%;
      height: 48px;
      padding: 8px;
      font-size: 14px;
      border: 1px solid #ccc;
      border-radius: 8px;
      box-sizing: border-box;
    }

    .errorText {
      margin-top: 4px;
      font-size: 12px;
      color: red;
    }
  }

  .assignButton {
    width: 100%;
    height: 40px;
    padding: 12px;
    font-size: 16px;
    color: #fff;
    background-color: #7b2aff;
    border: none;
    border-radius: 8px;
    cursor: pointer;

    &.disabled {
      background-color: #f6f6f6;
      color: #777777;
    }
  }
}
