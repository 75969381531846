.tileContainer {
  margin: 10px;
  border: 1px solid #ccc;
  background-color: #fbfbfb;
  padding: 16px;
  border-radius: 8px;
  width: 96%;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.tileRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}

.busRcText {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.tileTextLeft {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.tileTextRight {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: right;
}

.tileButtonRight {
  text-align: right;
  background-color: #f2eaff;
  color: #7b2aff;
  border: none;
  width: 80px;
  border-radius: 5px;
  padding: 6px 10px;
  cursor: pointer;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
}

.separator {
  height: 1px;
  background-color: #ccc;
  margin: 8px 0;
}
