.amenitiesRow {
  justify-content: start;
}

.searchHeader {
  background: rgb(248, 248, 248);
  display: flex;
  align-items: center;
  padding: 15px;
  justify-content: space-between;
  > div:nth-child(1) {
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: center;
  }
  > div:nth-child(2) {
    display: flex;
    gap: 20px;

    > div > img {
      margin: 0 10px;
    }
    > div > span {
      margin: 0px 10px;
      font-family: "Poppins-Medium";
      font-size: 12px;
    }
    > img {
      cursor: pointer;
      background: #7b2bff;
      padding: 5px;
      border-radius: 10px;
    }
  }
}
