@media only screen and (max-width: 768px) {
  body {
    -webkit-text-size-adjust: 100%;
  }
  .offers {
    display: flex;
    justify-content: space-between;
    padding: 3% 3%;
    border: 1px solid #dfdfdf;
    margin: 3% 0;
    border-radius: 10px;

    input {
      margin: 0px 5px 1px 0px;
      accent-color: #7b2bff;
    }
  }

  .offers > div {
    display: flex;
  }

  .serviceButton {
    position: sticky;
    bottom: 2px;
  }

  .minMaxDiscount {
    display: flex;
    justify-content: space-between;
  }

  .amountInput {
    margin-top: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    height: 45px;
    margin: 3% 0;

    border: 1px solid rgba(89, 0, 234, 0.45);
    .percentage {
      font-family: "Poppins-Medium";
      padding: 0% 2%;
      font-size: 12px;
      width: 10px;
      font-weight: 400;
      width: 20%;
      text-align: end;
      color: #8a8a8a;
    }
    .rupee {
      font-family: "Poppins-Medium";
      padding: 0% 2%;
      font-size: 12px;
      width: 10px;
      font-weight: 400;
    }
    .vl {
      border-left: 1px solid rgba(89, 0, 234, 0.45);
      height: 100%;
      margin-left: 10px;
      display: flex;
      align-items: center;
    }
    input {
      width: 100%;
      margin-left: 10px;
      border: none;
      font-family: "Poppins-Medium";
      font-size: 12px;
    }
  }

  input:focus {
    outline: none;
  }
  .stepBtn {
    background: #7b2bff;
    color: white;
    border: none;
    width: -webkit-fill-available;
    height: 45px;
    margin-top: 10%;
    padding: 2%;
    border-radius: 10px;
  }
  .step {
    margin: 5%;
  }
  .purple-Bg {
    accent-color: #7b2bff;
  }
  .heading {
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }
  .subHeading {
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }
  .dateSelect {
    width: 100%;
    height: 40px;
    border-radius: 10px;
    border: 1px solid #e1e4e9;
  }
  .dateSelect:focus {
    outline: none; //this will remove border when focused
  }
  .borderBottom {
    border-bottom: 1px solid #e4e4e4;
  }
  .selectService {
    width: 100%;
    height: 47px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    border: 1px solid #dfdfdf;
    border-radius: 10px;
  }

  .labelService {
    padding-left: 10px;
  }

  .allServices {
    border-bottom: 1px solid #444444;
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
  }

  .DealsHeader {
    background: url("../../images/dealBg.png");
    height: 50px;
    padding: 2%;
    font-family: "Poppins";
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dealSpan {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
  }
  .finalData {
    margin-bottom: 5px;
  }
  .finalCampSerices {
    height: 45px;
  }

  .percentageInput {
    width: 80%;
  }
  .disclaimer {
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #625fff;
  }
  .headerDetails {
    display: flex;
    span {
      padding: 7px;
    }
  }
  .finalDisclaminer {
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #006ded;
  }

  .days {
    font-family: "Poppins";
    font-size: 12px;
    background-color: #7b2bff;
    color: white;
    padding: 0 3px;
    margin: 1px;
  }

  #root > div > div.step > div:nth-child(5) > div > div > div > div > span {
    background-color: #7b2bff;
    color: white;
    font-family: "Poppins";
    font-size: 12px;
  }

  span.ant-select-selection-item-remove > span > svg {
    color: white;
  }

  .alignCentre {
    display: flex;
    align-items: center;
    img {
      margin-left: 2px;
    }
  }
  .step2Dates {
    margin: 15px 0px;
    .alignCentre {
      margin-bottom: 4px;
    }
  }

  .stepHeader {
    background: white;
    border-radius: 10px;
    padding: 0 10px;
  }
  .checkBox {
    padding: 10px 0px;
    height: fit-content;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    p {
      font-family: "Poppins";
      font-size: 12px;
    }
    input {
      accent-color: #7b2bff;
    }
  }

  div.step > div.ant-checkbox-group.css-dev-only-do-not-override-weuk1o {
    width: 100%;
    padding: 10px 0px;
  }

  .modalDiv {
    text-align: center;
    .modalHeader {
      font-family: "Poppins-Medium";
      font-size: 14px;
    }

    .modalSubHeader {
      font-family: "Poppins";
      font-size: 14px;
    }
  }
}
@media only screen and (min-width: 768px) {
  .DealsHeader {
    height: 50px;
    padding: 2% 3% 0px 1%;
    font-family: "Poppins";
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .dealSpan {
    font-family: "Poppins";
    font-size: 22px;
    font-weight: 600;
  }
  .modalDiv {
    text-align: center;
    .modalHeader {
      font-family: "Poppins-Medium";
      font-size: 20px;
      color: #454545;
    }
    .modalSubHeader {
      font-family: "Poppins";
      font-size: 14px;
      color: #454545;
    }
    .primary {
      background-color: #7b2bff;
      font-family: "Poppins";
      font-size: 14px;
      border: none;
      color: white;
      width: 50%;
      height: 40px;
      border-radius: 20px;
    }
    .rectangle {
      border-radius: 10px;
    }
    .cancelButton {
      font-family: "Poppins";
      font-size: 14px;
      color: #7b2bff;
    }
  }
  .step {
    overflow: hidden;
    width: 100%;

    > div.serviceInfo {
      padding-right: 20px;
    }
  }

  .left {
    border-right: 1px solid #eeeeee;
    width: 48%;
    float: left;

    > .details {
      padding-right: 20%;
    }
  }
  .right {
    width: 49%;
    overflow: hidden;
    > .details {
      padding-left: 20%;
    }
  }

  .containerPadding {
    padding: 0 20px;
  }

  .servicePadding {
    padding: 10px 20px 0px 10px;
    > .ant-checkbox-group {
      width: 100%;
    }
  }

  .offers {
    display: flex;
    justify-content: space-between;
    padding: 2%;
    border: 1px solid #dfdfdf;
    margin: 3% 0;
    border-radius: 10px;
    input {
      accent-color: #7b2bff;
    }
  }
  .amountInput {
    margin-top: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    height: 45px;
    margin: 3% 0;

    border: 1px solid rgba(89, 0, 234, 0.45);
    .percentage {
      font-family: "Poppins-Medium";
      padding: 0% 2%;
      font-size: 12px;
      width: 10px;
      font-weight: 400;
      width: 20%;
      text-align: end;
      color: #8a8a8a;
    }
    .rupee {
      font-family: "Poppins-Medium";
      padding: 0% 2%;
      font-size: 12px;
      width: 10px;
      font-weight: 400;
    }
    .vl {
      border-left: 1px solid rgba(89, 0, 234, 0.45);
      height: 100%;
      margin-left: 10px;
      display: flex;
      align-items: center;
    }
    input {
      width: 100%;
      margin-left: 10px;
      border: none;
      font-family: "Poppins-Medium";
      font-size: 12px;
    }
  }

  .textInput {
    margin-top: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    height: 45px;
    margin: 3% 0;

    border: 1px solid #e1e4e9;
    .percentage {
      font-family: "Poppins-Medium";
      padding: 0% 2%;
      font-size: 12px;
      width: 10px;
      font-weight: 400;
      width: 20%;
      text-align: end;
      color: #8a8a8a;
    }
    .rupee {
      font-family: "Poppins-Medium";
      padding: 0% 2%;
      font-size: 12px;
      width: 10px;
      font-weight: 400;
    }
    .vl {
      border-left: 1px solid #e1e4e9;
      height: 100%;
      margin-left: 10px;
      display: flex;
      align-items: center;
    }
    input {
      width: 100%;
      margin-left: 10px;
      border: none;
      font-family: "Poppins";
      font-size: 14px;
    }
  }

  input:focus {
    outline: none;
  }
  .buttonAlign {
    text-align: end;
    margin-right: 3%;
    .stepBtn {
      cursor: pointer;
      margin-bottom: 20%;
      font-size: 14px;
      background: #7b2bff;
      color: white;
      border: none;
      width: 159px;
      height: 50px;
      margin-top: 5%;
      padding: 1% 2%;
      border-radius: 10px;
    }
  }

  .purple-Bg {
    accent-color: #7b2bff;
  }
  .heading {
    font-family: "Poppins-Medium";
    font-size: 16px;
    font-weight: 500;
    text-align: left;
  }
  .subHeading {
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }
  .dateSelect {
    width: 100%;
    height: 40px;
    border-radius: 10px;
    border: 1px solid #e1e4e9;
  }
  .dateSelect:focus {
    outline: none; //this will remove border when focused
  }
  .borderBottom {
    border-bottom: 1px solid #e4e4e4;
  }
  .selectService {
    width: fit-content;
    height: 47px;
    display: flex;
    align-items: center;
    padding: 0.5% 10% 0.5% 0.5%;
    border: 1px solid #dfdfdf;
    border-radius: 10px;
  }

  .labelService {
    padding-left: 10px;
  }

  .allServices {
    border-bottom: 1px solid #444444;
    display: flex;
    justify-content: space-between;
    margin-top: 2%;
    margin-bottom: 1%;
    > span:nth-child(2) {
      margin-right: 3%;
    }
  }

  .finalData {
    margin-bottom: 5px;
  }
  .finalCampSerices {
    height: 45px;
  }

  .percentageInput {
    width: 80%;
  }
  .disclaimer {
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #625fff;
  }
  .headerDetails {
    img {
      cursor: pointer;
    }
    display: flex;
    span {
      padding: 7px;
    }
  }
  .finalDisclaminer {
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #006ded;
  }

  .days {
    font-family: "Poppins";
    font-size: 12px;
    background-color: #7b2bff;
    color: white;
    padding: 0 3px;
    margin: 1px;
  }

  #root
    > div
    > div.container
    > div.step
    > div.left
    > div
    > div:nth-child(5)
    > div
    > div
    > div
    > div
    > span {
    background-color: #7b2bff;
    color: white;
    font-family: "Poppins";
    font-size: 12px;
  }
  .alignCentre {
    display: flex;
    align-items: center;
    img {
      margin-left: 2px;
    }
  }
  .step2Dates {
    margin: 20px 0px;
    .alignCentre {
      margin-bottom: 4px;
    }
  }
  .dateRangePicker .ant-picker-panel:nth-child(2) {
    display: none;
  }

  .dateRangePicker .ant-picker-panel:nth-child(1) button {
    visibility: visible !important;
  }

  .stepHeader {
    background: white;
    border-radius: 10px;
    padding: 0 10px;
  }
  .checkBox {
    padding: 10px 0px;
    margin-right: 2%;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    p {
      font-family: "Poppins";
      font-size: 14px;
    }
    input {
      accent-color: #7b2bff;
    }
  }
}
