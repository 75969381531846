.MoreInfoTile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
  background-color: #f9f9f9;
  margin: 10px;
  gap: 12px;
  .heading {
    text-align: center;
    margin-bottom: 20px;

    h1 {
      margin: 0;
      font-size: 24px;
    }
  }

  .subHeading {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    > span {
      margin: 0;
      font-family: Poppins;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
    }

    > span:nth-child(1) {
      font-weight: 600;
    }
  }
  .subHeadingComment {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    flex-direction: column;
    > span {
      margin: 0;
      font-family: Poppins;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
    }

    > span:nth-child(1) {
      font-weight: 600;
    }
  }
  .tag {
    border-radius: 39px;
    background: #ffe5e5;
    padding: 3px 8px 3px 4px;
    span {
      font-family: Poppins;
    }
  }
  .tagNegative {
    border-radius: 39px;
    background: #f3ecff;
    padding: 3px 8px 3px 4px;
    span {
      font-family: Poppins;
    }
  }
}
