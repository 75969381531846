.inspectionDataMoreInfoTile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
  background-color: #f9f9f9;
  margin: 10px;

  .heading {
    text-align: center;
    margin-bottom: 20px;

    h1 {
      margin: 0;
      font-size: 24px;
    }
  }

  .subSection {
    width: 100%;

    .subHeading {
      margin-bottom: 20px;

      span {
        margin: 0;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
      }

      p {
        font-family: Poppins;
        padding-top: 0px;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
      }
    }
  }

  .options {
    display: flex;
    width: 100%;
    margin-top: 20px;

    .option {
      display: flex;
      align-items: center;

      img {
        margin-right: 8px;
        width: 20px;
        height: 20px;
      }

      a {
        text-decoration: underline;
        color: #007bff;
        cursor: pointer;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
