.appliedFiltersTabs {
  width: 100%;
  overflow-x: auto;
  display: flex;
  align-items: center;
  padding: 2px 8px;
  background-color: #ffffff;

  .tabsContainer {
    display: inline-flex;
    gap: 8px;
  }

  .tab {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    background-color: #f6f6f6;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      background-color: #d6d6d6;
    }

    .filterText {
      margin-right: 8px;
      font-size: 14px;
      color: #333;
    }

    .removeIcon {
      width: 12px;
      height: 12px;
      cursor: pointer;
    }
  }
}
