.amenitiesRow {
  justify-content: start;
}

.searchHeader {
  background: rgb(248, 248, 248);
  display: flex;
  align-items: center;
  padding: 15px;
  justify-content: space-between;
  > div:nth-child(1) {
    width: 40%;
    display: flex;
    gap: 10px;
    align-items: center;
  }
  > div:nth-child(2) {
    display: flex;
    gap: 20px;

    > div > img {
      margin: 0 10px;
    }
    > div > span {
      margin: 0px 10px;
      font-family: "Poppins-Medium";
      font-size: 12px;
    }
    > img {
      cursor: pointer;
      background: #7b2bff;
      padding: 5px;
      border-radius: 10px;
    }
  }
}
.modalDetails {
  > .header {
    display: flex;
    gap: 5px;
    align-items: center;
    > span:nth-child(1) {
      font-family: Poppins;
      font-size: 22px;
      font-weight: 600;
      line-height: 20px;
      text-align: left;
    }
    > span:nth-child(2) {
      display: flex;
      padding: 4px 8px;
      align-items: flex-start;
      gap: 10px;
      border-radius: 4px;
      background: #f6f6f6;
    }
  }
  .contractDetails {
    width: 100%;
    margin: 20px auto;
    border-collapse: collapse;
    display: flex;
  }
  .contractDetails table {
    width: 50%;
  }
  .contractDetails th,
  .contractDetails td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
  }

  .contractDetails th {
    background-color: #f2f2f2;
  }
}
