.amenitiesRow {
  justify-content: start;
  > div {
    display: flex;

    > .bulkDiv {
      display: flex;
      justify-content: center;
      border: none;
      color: white;
      margin: 10px;
      > span {
        cursor: pointer;
        font-family: "Poppins-Medium";
        margin-right: 10px;
        font-size: 12px;
        background: #8f00ff;
        border-radius: 10px;
        padding: 10px 5px;
      }
    }
    > .InActivebulkDiv {
      display: flex;
      justify-content: center;
      border: none;
      color: white;
      margin: 10px;
      > span {
        cursor: pointer;
        font-family: "Poppins-Medium";
        font-size: 12px;
        margin-right: 10px;
        background: #cccccc;
        border-radius: 10px;
        padding: 10px 5px;
      }
    }
  }
}

.searchHeader {
  background: rgb(248, 248, 248);
  display: flex;
  align-items: center;
  padding: 15px;
  justify-content: space-between;
  > div:nth-child(1) {
    width: 40%;
    display: flex;
    gap: 10px;
    align-items: center;
  }
  > div:nth-child(2) {
    display: flex;
    gap: 20px;

    > div > img {
      margin: 0 10px;
    }
    > div > span {
      margin: 0px 10px;
      font-family: "Poppins-Medium";
      font-size: 12px;
    }
    > img {
      cursor: pointer;
      background: #7b2bff;
      padding: 5px;
      border-radius: 10px;
    }
  }
}

.ratingInfo {
  margin: 12px 0px;
  gap: 16px;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  span {
    color: #000;

    font-feature-settings: "ss01" on, "cv01" on, "cv11" on;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
  > .avgRating {
    padding: 12px 16px;
    border-radius: 5px;
    background: #f5f5f5;
    gap: 5px;
    width: 33%;
    display: flex;
    align-items: center;
    gap: 12px;
    > div {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 5px;
      > span:nth-child(2) {
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
  > .positiveRating {
    border-radius: 5px;
    background: #f3ecff;
    display: flex;
    padding: 12px 11px;
    align-items: center;
    gap: 12px;
    width: 33%;
    flex: 1 0 0;
    > div {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 5px;
      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        > div {
          display: flex;
          padding: 3px 6px;
          align-items: center;
          gap: 6px;
          border-radius: 4px;
          background: #fff;
        }
      }
      > span {
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
  > .negRating {
    border-radius: 5px;
    background: #ffe5e5;
    display: flex;
    padding: 12px 11px;
    align-items: center;
    gap: 12px;
    width: 33%;
    flex: 1 0 0;
    > div {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 5px;
      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        > div {
          display: flex;
          padding: 3px 6px;
          align-items: center;
          gap: 6px;
          border-radius: 4px;
          background: #fff;
        }
      }
      > span {
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
}

.TextArea {
  margin-bottom: 20px;
}

.tableTitle {
  display: flex;
  align-items: center;
  > span {
    margin-right: 5px;
  }
}

.commisiondiv {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  > span {
    font-family: Poppins;
    font-size: 14px;
    margin-right: 10px;
  }
}

.modalDateInfo {
  margin-bottom: 10px;

  > span:nth-child(1) {
    font-family: "Poppins-Medium";
    font-size: 14px;
  }
  > span:nth-child(2) {
    color: #7b2bff;
    font-family: "Poppins-Medium";
    font-size: 14px;
  }
}
.divButton {
  display: flex;
  justify-content: space-around;
  align-items: center;
  > button:nth-child(1) {
    cursor: pointer;
    width: 48%;
    background: #ededed;
    color: black;
    border: none;
    padding: 10px;
    border-radius: 10px;
    font-family: "Poppins";
  }
  > button:nth-child(2) {
    cursor: pointer;
    width: 48%;
    padding: 10px;
    border: none;
    border-radius: 10px;
    font-family: "Poppins";
    background: #7b2aff;
    color: white;
  }
}

.tableDiv {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  > img {
    cursor: pointer;
  }
}

.tableType {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}

.bulkServiceUpdate {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.loader {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 50%;
  right: 50%;
}

.attachment {
  cursor: pointer;
  > div {
    display: flex;
    align-items: center;
  }
}
.tag {
  border-radius: 39px;
  background: #ffe5e5;
  padding: 3px 8px 3px 4px;
  span {
    font-family: Poppins;
  }
}
.tagNegative {
  border-radius: 39px;
  background: #f3ecff;
  padding: 3px 8px 3px 4px;
  span {
    font-family: Poppins;
  }
}
.moreDetailsModal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  > img {
    cursor: pointer;
  }
  > div:nth-child(1) {
    display: flex;
    align-items: center;
    gap: 12px;
    > * {
      color: #292929;
      font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    > p:nth-child(1) {
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
    }
    > p:nth-child(2) {
      height: 22px;
      display: flex;
      padding: 4px 8px;
      align-items: flex-start;
      gap: 10px;
      border-radius: 4px;
      background: #f6f6f6;
      color: #292929;
      font-family: "Poppins-Medium";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    > div:nth-child(3) {
      height: 22px;
      display: flex;
      padding: 4px 8px;
      align-items: center;
      gap: 6px;
      border-radius: 4px;
      background: #f6f6f6;
      p {
        margin: 0;
        color: #292929;
        font-family: "Poppins-Medium";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .pageHeading {
    display: flex;
    margin-left: 20px;
    .backImg {
      margin-right: 10px;
    }
    .showMorePageHeading {
      display: flex;
      gap: 8px;
      align-items: center;
      .pageHeadingText {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
      }
      .showMorepPageHeadingRC {
        border-radius: 4px;
        background: #f6f6f6;
        padding: 4px 8px;
        > span {
          color: #292929;
          font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
      .showMorepPageHeadingRating {
        display: flex;
        padding: 4px 8px;
        align-items: flex-start;
        gap: 6px;
        border-radius: 4px;
        background: #f6f6f6;
        > span {
          color: #292929;

          font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
  }
  .searchHeader {
    background: rgb(248, 248, 248);
    display: block;
    padding: 15px;
    justify-content: space-between;
    > div:nth-child(1) {
      width: 40%;
      display: flex;
      gap: 10px;
      align-items: center;
    }
    > div:nth-child(2) {
      display: flex;
      gap: 20px;

      > div > img {
        margin: 0 10px;
      }
      > div > span {
        margin: 0px 10px;
        font-family: "Poppins-Medium";
        font-size: 12px;
      }
      > img {
        cursor: pointer;
        background: #7b2bff;
        padding: 5px;
        border-radius: 10px;
      }
    }
  }
  .ratingInfo {
    margin: 12px 0px;
    gap: 8px;
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    flex-direction: column;
    span {
      color: #000;

      font-feature-settings: "ss01" on, "cv01" on, "cv11" on;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
    > .avgRating {
      border-radius: 5px;
      background: #f5f5f5;
      gap: 5px;
      width: -webkit-fill-available;
      display: flex;
      align-items: center;
      gap: 12px;
      > div {
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        gap: 5px;
        > span:nth-child(2) {
          font-size: 18px;
          font-weight: 500;
        }
      }
    }
    > .positiveRating {
      border-radius: 5px;
      background: #f3ecff;
      display: flex;
      align-items: center;
      gap: 12px;
      width: -webkit-fill-available;
      flex: 1 0 0;
      > div {
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        gap: 5px;
        > div {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          > div {
            display: flex;
            padding: 3px 6px;
            align-items: center;
            gap: 6px;
            border-radius: 4px;
            background: #fff;
          }
        }
        > span {
          font-size: 18px;
          font-weight: 500;
        }
      }
    }
    > .negRating {
      border-radius: 5px;
      background: #ffe5e5;
      display: flex;
      align-items: center;
      gap: 12px;
      width: -webkit-fill-available;
      flex: 1 0 0;
      > div {
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        gap: 5px;
        > div {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          > div {
            display: flex;
            padding: 3px 6px;
            align-items: center;
            gap: 6px;
            border-radius: 4px;
            background: #fff;
          }
        }
        > span {
          font-size: 18px;
          font-weight: 500;
        }
      }
    }
  }
}
