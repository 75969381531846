@media only screen and (max-width: 768px) {
    body{
        -webkit-text-size-adjust: 100%;
      }
    .box{
        padding: 10px;
        border: 1px solid #E5E5E5;
        border-radius: 8px;
        margin: 5% 5%;
        p{
            margin: 05px 0px ;
        }
        .bold{
            font-family: 'Poppins-Medium';
            font-size: 12px;
        }
        .text{
            font-family: 'Poppins';
            text-transform: capitalize;
            font-size: 12px;

        }
        .button{
            background: #7b2bff;
            color: white;
            font-size: 14px;
            padding: 5px;
            font-family: 'Poppins-Medium';
            border-radius: 10px;
        }
        .upper{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .wrapper{
            width: 100%;
            overflow: hidden;

        }
        .first{
            width: 50%;
            float: left;
        }
        .second{
            overflow: hidden;
        }
    }
}

@media only screen and (min-width: 768px){

    .box{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px;
        border: 1px solid #E5E5E5;
        border-radius: 8px;
        margin: 12px;
        p{
            margin: 05px 0px ;
        }
        .bold{
            font-family: 'Poppins-Medium';
            font-size: 12px;
        }
        .text{
            font-family: 'Poppins';
            text-transform: capitalize;
            font-size: 12px;

        }
        .button{
            background: #7b2bff;
            color: white;
            font-size: 12px;
            padding: 5px;
            font-family: 'Poppins-Medium';
            border-radius: 10px;
            text-align: center;
            border: none;
        }
        .details{
            width: 25%;
            text-align: center;

        }


    }
}